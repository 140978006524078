import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
//
import Iconify from '../../iconify';
import { varFade } from '../../animate';
import FileThumbnail, { fileData } from '../../file-thumbnail';
// ----------------------------------------------------------------------
export default function MultiFilePreview({ thumbnail, files, onRemove, sx }) {
    if (!files?.length) {
        return null;
    }
    return (_jsx(AnimatePresence, { initial: false, children: files.map((file) => {
            const { key, name = '', size = 0 } = fileData(file);
            const isNotFormatFile = typeof file === 'string';
            if (thumbnail) {
                return (_jsxs(Stack, { component: m.div, ...varFade().inUp, alignItems: "center", display: "inline-flex", justifyContent: "center", sx: {
                        m: 0.5,
                        width: 80,
                        height: 80,
                        borderRadius: 1.25,
                        overflow: 'hidden',
                        position: 'relative',
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                        ...sx,
                    }, children: [_jsx(FileThumbnail, { tooltip: true, imageView: true, file: file, sx: { position: 'absolute' }, imgSx: { position: 'absolute' } }), onRemove && (_jsx(IconButton, { size: "small", onClick: () => onRemove(file), sx: {
                                top: 4,
                                right: 4,
                                p: '1px',
                                position: 'absolute',
                                color: (theme) => alpha(theme.palette.common.white, 0.72),
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                },
                            }, children: _jsx(Iconify, { icon: "eva:close-fill", width: 16 }) }))] }, key));
            }
            return (_jsxs(Stack, { component: m.div, ...varFade().inUp, spacing: 2, direction: "row", alignItems: "center", sx: {
                    my: 1,
                    px: 1,
                    py: 0.75,
                    borderRadius: 0.75,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    ...sx,
                }, children: [_jsxs(Stack, { flexGrow: 1, sx: { minWidth: 0 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: isNotFormatFile ? file : name }), _jsx(Typography, { variant: "caption", sx: { color: 'text.secondary' }, children: isNotFormatFile ? '' : fData(size) })] }), onRemove && (_jsx(IconButton, { edge: "end", size: "small", onClick: () => onRemove(file), children: _jsx(Iconify, { icon: "eva:close-fill" }) }))] }, key));
        }) }));
}
